<!--线下出库-->
<template>
    <div class="mainBox">
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-c flex-wrap">
                <div>
                    <label style="color:#333">扫码枪：</label>
                    <!-- <el-input placeholder="输入/扫描条形码" id="code" ref="code" v-model="select.code" size="small" @keydown.enter.native="courierNumberEnterDown()" @keyup.enter.native="courierNumberEnterUp()" style="width:300px">
                        <el-button slot="append" icon="el-icon-search" @click="getShop" ></el-button>
                    </el-input> -->
                    <el-input placeholder="输入/扫描条形码" id="code" ref="code" v-model="select.code" size="small" style="width:300px">
                        <el-button slot="append" icon="el-icon-search" @click="getShop"></el-button>
                    </el-input>

                    <!-- <el-input v-model="select.code" ref="code" id="code" clearable @input="changeCode()" @keydown.enter.native="courierNumberEnterDown" @keyup.enter.native="courierNumberEnterUp" size="small" style="width:300px">
                        <el-button slot="append" icon="el-icon-search" @click="getShop"></el-button>
                    </el-input> -->

                </div>
            </div>
        </div>

        <!--主要内容-->
        <el-row class="outHouse" :gutter="20" style="height:100%">
            <el-col style="width:calc(100% - 400px);height:100%">
                <div class="table" style="height:calc( 100% - 110px );box-shadow:none">
                    <el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px)">
                        <el-table-column prop="code" label="条形码" width="150" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="address" label="商品主图">
                            <template slot-scope="scope">
                                <el-image class="tableImage" :src="domain + scope.row.image" :preview-src-list="[domain + scope.row.image]"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="商品名称" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="cost" label="进价" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="offline_price" label="线下售价" show-overflow-tooltip></el-table-column>
                        <el-table-column label="数量" show-overflow-tooltip width="150">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.number" size="mini" @input="handleNumChange(scope.row)" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column label="小计(元)" show-overflow-tooltip>
                            <template slot-scope="scope">
                                {{scope.row.amount_payable}}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" fixed="right" width="150">
                            <template slot-scope="scope">
                                <el-button type="danger" size="mini" icon="el-icon-delete" @click="deletes(scope.$index)"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <!-- 出库 -->
                    <div class="paging flex flex-jc-fe flex-ai-c" v-if="list.length!=0">
                        <el-button type="text">应付金额：<span style="font-size:18px;color:#606266"> {{amountPayable}} </span>元</el-button>
                        <div style="font-size:14px;margin:0 20px;color: #7B84FF;" class="amount">
                            实付金额：
                            <el-input style="width:120px;background:none;font-size:18px" v-model="amountTotal" size="mini" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)"></el-input>
                            元
                        </div>
                        <el-button style="width:130px" type="primary" plain size="mini" @click="outHouseSubmit()">出库</el-button>
                    </div>
                </div>
            </el-col>

            <!--会员卡-->
            <el-col style="width:400px;height:100%">
                <div class="cardBox">
                    <el-row type="flex" justify="space-between" align="middle" class="title">
                        <div>
                            织脉数字化商城连锁版会员卡
                            <span v-if="memberData.level_id_deadline">({{memberData.level_id_deadline}}到期)</span>
                        </div>
                        <div>{{memberData.levelName}}</div>
                    </el-row>
                    <el-row type="flex" align="middle">
                        <label>卡号</label>
                        <el-input v-model="memberData.card" size="mini" placeholder="卡号"></el-input>
                    </el-row>
                    <el-row type="flex" align="middle">
                        <label>手机号</label>
                        <el-input v-model="memberData.mobile" size="mini" placeholder="手机号" @change="updateMember()"></el-input>
                    </el-row>
                    <el-row type="flex" align="middle">
                        <label>会员姓名</label>
                        <el-input v-model="memberData.realname" size="mini" placeholder="会员姓名"></el-input>
                    </el-row>
                    <el-row type="flex" align="middle">
                        <label>当前积分</label>
                        <el-input v-model="memberData.integral" readonly size="mini" placeholder="当前积分"></el-input>
                    </el-row>
                    <el-row type="flex" align="middle">
                        <label>当前余额</label>
                        <el-input v-model="memberData.balance" readonly size="mini" placeholder="当前余额"></el-input>
                    </el-row>
                </div>

                <div class="couponBox">
                    <div class="coupon_bg flex" v-for="(item,index) in memberData.userDiscount" :key="index">
                        <div class="jian">
                            <span>{{item.reduce}}</span>
                            <b>{{item.discount_name}}</b>
                        </div>
                        <div class="man">
                            满{{item.full}}元使用
                        </div>
                    </div>
                    <div v-if="!memberData.userDiscount || memberData.userDiscount.length == 0" class="flex flex-column flex-jc-c  flex-ai-c" style="height:100%">
                        <img src="../../../assets/noData.png" width="200px" />
                        <p style="color:#909399;font-size:14px">暂无优惠券</p>
                    </div>
                </div>
            </el-col>
        </el-row>

    </div>
</template>
<script>
import { outHouseApi, outHouseShopListApi, outHouseSaveApi } from '@/api/house.js';
export default {
    data() {
        return {
            list: [], //出库商品
            select: {},
            oldCode: '',
            memberData: {},
            totalPay: 0,
            amountPayable: 0, //应付总金额
            amountTotal: 0, //实付总金额

            code: '',
            lastTime: null,

            isShowCode: false,

            keyDownTime: null,
            keyUpTime: null,
        }
    },
    watch: {
        'memberData.mobile': function (val) {
            let mobilePreg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
            if (val.length == 11 && mobilePreg.test(val)) {
                this.getUser();
                return;
            } else if (val.length == 11 && !mobilePreg.test(val)) {
                this.$message({
                    type: 'error',
                    message: '请输入正确的手机号!'
                });
                return;
            }
        },
    },
    created() {
        this.getBarCode();
    },
    mounted() {
        this.$refs['code'].focus();
    },
    methods: {
        //扫码枪执行
        getBarCode: function () {
            let code = ''
            let lastTime, nextTime
            let lastCode, nextCode
            let that = this
            this.$nextTick(() => {
                window.document.getElementById('code').onkeypress = function (e) {
                    if (window.event) {
                        // IE
                        nextCode = e.keyCode
                    } else if (e.which) {
                        // Netscape/Firefox/Opera
                        nextCode = e.which
                    }
                    if (e.which === 13) {
                        if (code.length < 3) {
                            that.parseQRCode(that.select.code);
                            return
                        }  // 手动输入的时间不会让code的长度大于2，所以这里只会对扫码枪有
                        console.log('扫码结束')

                        that.parseQRCode(code) // 获取到扫码枪输入的内容，做别的操作
                        code = ''
                        lastCode = ''
                        lastTime = ''
                        return
                    }
                    nextTime = new Date().getTime()
                    if (!lastTime && !lastCode) {
                        console.log('扫码开始。。。')
                        code += e.key
                    }

                    if (lastCode && lastTime && nextTime - lastTime > 500) {
                        // 当扫码前有keypress事件时,防止首字缺失
                        console.log('防止首字缺失。。。')
                        code = e.key
                    } else if (lastCode && lastTime) {
                        console.log('扫码中。。。')
                        code += e.key
                    }
                    lastCode = nextCode
                    lastTime = nextTime
                }
            })
        },

        //获取code码执行方法
        parseQRCode: function (code) {
            this.select.code = code;
            this.getShop();
        },

        
        updateMember: function () {
            if (this.memberData.mobile.length != 11) {
                this.getUser();
            }
        },

        //金额计算
        handleNumChange: function (data) {
            data.amount_payable = data.number * data.offline_price;
            data.amount = data.number * data.offline_price;
            this.totalChange();
        },

        //总计计算
        totalChange: function () {
            let _this = this;
            //修改出库数量初始应付以及实付
            this.amountTotal = 0;
            this.amountPayable = 0;
            this.list.forEach(function (item) {
                //应付总金额
                _this.amountPayable += (Number(item.number) * item.offline_price);
                //实付总金额
                _this.amountTotal += (Number(item.number) * item.offline_price);
            })
        },

        //扫码枪扫码or输入code码获取商品信息
        getShop: function () {
            if (!this.select.code) {
                this.$message({
                    type: 'error',
                    message: '请输入/扫描条形码！'
                });
                return;
            }
            outHouseShopListApi({
                code: this.select.code,
                level: this.memberData.level_id
            }).then(response => {
                //出库数量初始值
                this.$set(response, 'number', 1);
                //出库商品列表
                let index = this.list.findIndex(item => Number(item.code) == this.select.code);

                if (index === -1) {
                    //实付金额初始值
                    this.$set(response, 'amount', response.number * response.offline_price);
                    //应付金额初始值
                    this.$set(response, 'amount_payable', response.number * response.offline_price);
                    //不存在商品
                    this.list.push(response);
                } else {
                    //商品存在数量增1
                    this.list[index].number++;
                    //重新计算应付金额和实付金额
                    this.list[index].amount_payable = this.list[index].number * this.list[index].offline_price;
                    this.list[index].amount = this.list[index].number * this.list[index].offline_price;
                }
                //计算总金额
                this.totalChange();
            })
        },

        //移除扫码商品
        deletes: function (index) {
            this.list.splice(index, 1)
        },

        //根据手机号获取会员信息
        getUser: function () {
            outHouseApi({
                mobile: this.memberData.mobile
            }).then(response => {
                //会员信息
                this.memberData = response;
                //若有当前人会员信息
                this.isShowCode = true;
            })
        },

        //线下进行出库
        outHouseSubmit: function () {
            let details = [];
            for (let item of this.list) {
                if (!item.number || item.number == 0) {
                    this.$message({
                        type: 'error',
                        message: '请输入出库数量!'
                    });
                    return;
                }
                if (item.number > item.stock) {
                    this.$message({
                        type: 'error',
                        message: '出库数量超过库存数量禁止出库，请重新输入数量!'
                    });
                    return;
                }

                details.push({
                    product_id: item.product_id,
                    product_name: item.name,
                    product_pic: item.image,
                    attr_id: item.attr_id,
                    code: item.code,
                    attr_name: item.sku,
                    price: item.offline_price,
                    number: item.number,
                })
            }
            outHouseSaveApi({
                mobile: this.memberData.mobile,
                amount: this.amountTotal,
                details: details
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '出库成功!'
                });
            })
        }
    }
};
</script>